import React from "react";

export default class TransparentButton extends React.Component {
  render() {
    const { link, btnText } = this.props;
    return (
      <a
        href={link}
        className="btn btn-lg btn-block border-light text-light"
        style={{ borderRadius: 0 }}
      >
        {btnText}
      </a>
    );
  }
}
