import React from "react";
import axios from "axios";

import Input from "./Input";

export default class Form extends React.Component {
  //Set up constructor
  constructor(props) {
    super(props);

    //Initialise state
    this.state = {
      payload: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        selectedService: "",
      },
    };

    //Bind functions
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleDB = this.handleDB.bind(this);
    this.handlePixel = this.handlePixel.bind(this);
    this.handleMailChimp = this.handleMailChimp.bind(this);
    this.clearState = this.clearState.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  //STATE HANDLERS ===============================
  handleFormChange(e) {
    this.setState({
      payload: {
        ...this.state.payload,
        [e.target.name]: e.target.value,
      },
    });
  }

  //API CALLS
  handlePixel() {
    window.fbq("track", "Message");
  }

  handleDB(payload) {
    axios
      .post("https://api.curiouscatcreative.com/clients", payload)
      .then((res) => {
        this.setState({
          ...this.state,
        });

        alert(
          "Thanks for sharing your details, check your email or follow the next step to book time with us!"
        );
      })
      .catch((error) => {
        alert(error);
      });
  }

  handleMailChimp(payload) {
    axios
      .post("https://api.curiouscatcreative.com/subscribe", {
        ...payload,
        tags: ["Customer"],
      })
      .then((res) => {})
      .catch((error) => {
        alert(error);
      });
  }

  clearState() {
    this.setState({
      ...this.state,
      payload: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        selectedService: "",
      },
    });
  }

  handleSubmit() {
    this.handlePixel();
    this.handleDB(this.state.payload);
    this.clearState();
  }

  render() {
    return (
      <div className="row formCard">
        <div className="col-12 text-center pb-2">
          <h2>SEND A MESSAGE</h2>
        </div>
        <div className="col-12 col-sm-6">
          <Input
            id="firstName"
            type="text"
            placeholder="First Name"
            name="firstName"
            value={this.state.payload.firstName}
            handleChange={this.handleFormChange}
          />
        </div>
        <div className="col-12 col-sm-6">
          <Input
            id="lastName"
            type="text"
            placeholder="Last Name"
            name="lastName"
            value={this.state.payload.lastName}
            handleChange={this.handleFormChange}
          />
        </div>
        <div className="col-12">
          <Input
            id="email"
            type="email"
            placeholder="test@example.com"
            name="email"
            value={this.state.payload.email}
            handleChange={this.handleFormChange}
          />
        </div>

        <div className="col-12">
          <Input
            id="phone"
            type="phone"
            placeholder="083 555 2755"
            name="phone"
            value={this.state.payload.phone}
            handleChange={this.handleFormChange}
          />
        </div>

        <div className="col-12 pt-2">
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="selectedService"
              id="corporate"
              value="Corporate"
              checked={this.state.payload.selectedService === "Corporate"}
              onChange={this.handleFormChange}
            />
            <label className="form-check-label" htmlFor="corporate">
              <strong>Corporates</strong> - I'm looking to creatively optimise
              my ESD spend
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="selectedService"
              id="incubatee"
              value="Supplier/Enterprise"
              checked={
                this.state.payload.selectedService === "Supplier/Enterprise"
              }
              onChange={this.handleFormChange}
            />
            <label className="form-check-label" htmlFor="corporate">
              <strong>Enterprises/Suppliers</strong> - I want to apply for the
              incubator programme
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="selectedService"
              id="investor"
              value="Investor"
              checked={this.state.payload.selectedService === "Investor"}
              onChange={this.handleFormChange}
            />
            <label className="form-check-label" htmlFor="investor">
              <strong>Investors</strong> - I would like to invest in incubator
              startups
            </label>
          </div>
        </div>

        <div className="col-12 py-3">
          <button
            className="btn btn-lg btn-block border-light text-light"
            onClick={this.handleSubmit}
            style={{ borderRadius: 0 }}
          >
            SUBMIT
          </button>
        </div>
      </div>
    );
  }
}

// TODO: Add form validation
