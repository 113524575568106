import React from "react";

import Jumbotron from "./jumbotron/Jumbotron";
import Story from "./story/Story";
import Services from "./services/Services";
import Projects from "./projects/Projects";
import About from "./about/About";
import Contact from "./contact/Contact";

export default class Main extends React.Component {
  render() {
    return (
      <div>
        <Jumbotron />
        <Story />
        <Services />
        <Projects />
        <About />
        <Contact />
      </div>
    );
  }
}
