import React from "react";
import axios from "axios";
import ReactMarkdown from "react-markdown";

import textMD from "./privacy.md";

export default class Privacy extends React.Component {
  constructor() {
    super();

    this.state = { markdown: "" };
  }

  componentDidMount() {
    axios.get(textMD).then((res) => {
      this.setState({
        markdown: res.data,
      });
    });
  }

  render() {
    return (
      <div className="container-fluid height-100 bg-light">
        <div className="row justify-content-center">
          <div className="col-12 col-md-8 col-lg-6">
            <ReactMarkdown children={this.state.markdown} />
          </div>
        </div>
      </div>
    );
  }
}
