import React from "react";

export default class AboutText extends React.Component {
  render() {
    return (
      <div className="row justify-content-start text-center text-lg-left">
        <div className="col-12">
          <h1 className="display-4">
            <strong>Meet the team behind Curious Cat Creative</strong>
          </h1>
        </div>
        <div className="col-12">
          <h2>Head to our profiles to learn more about us</h2>
        </div>
      </div>
    );
  }
}
