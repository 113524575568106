import React from "react";
import axios from "axios";

//import components
import Header from "../../common/Header";
import StoryBlock from "./StoryBlock";

export default class Story extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      text: "",
    };

    this.getStory = this.getStory.bind(this);
  }

  // API CALLS ==========================
  getStory() {
    axios.get("https://www.api.curiouscatcreative.com/story").then((res) => {
      this.setState({
        title: res.data.title,
        text: res.data.text
      })
    });
  }

  //LIFECYCLE METHODS ==================
  componentDidMount() {
    this.getStory();
  }

  render() {
    const {title, text} = this.state;
    return (
      <div id="story" className="container-fluid height-60 gradient">
        <div className="row justify-content-center align-content-center text-center text-light">
          <div className="col-12">
            <Header title={title} />
          </div>
          <div className="col-12 col-md-10 col-lg-8">
            <StoryBlock text={text}/>
          </div>
        </div>
      </div>
    );
  }
}
