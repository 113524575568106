import React from "react";

import { FaMapMarkerAlt } from 'react-icons/fa';


export default class ContactBlock extends React.Component {
  render(){
    return(
        <div className="row p-3">
          <div className="col-6 text-right">
            <FaMapMarkerAlt size={50}/>
          </div>
          <div className="col-6">
            <h5>Our Office</h5>
            <h5><small>Woodstock, Cape Town, South Africa</small></h5>
          </div>
        </div>
    )
  }
}
