import React from "react";
import axios from "axios";

//Import components
import Avatar from "./Avatar";

export default class Profiles extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      avatars: [],
    };

    this.getProfiles = this.getProfiles.bind(this);
  }

  // API CALLS ==========================
  getProfiles() {
    axios.get("https://www.api.curiouscatcreative.com/avatars").then((res) => {
      this.setState({
        avatars: res.data,
      });
    });
  }

  //LIFECYCLE METHODS ==================
  componentDidMount() {
    this.getProfiles();
  }

  render() {
    const avatars = this.state.avatars.map((avatar) => {
      let colour = "";
      let text = ""
      if (avatar.name === "Keshav")
      {
        colour = "primary"
        text = "ABOUT HIM"
      }
      else if (avatar.name === "Shulka") {
        colour = "secondary"
        text = "ABOUT HER"
      }

      return (
        <div className="col-6" key={avatar._id}>
          <Avatar
            link={avatar.link}
            btnText={text}
            imgsrc={avatar.image.url}
            colour={colour}
          />
        </div>
      );
    });

    return <div className="row p-0 m-0">{avatars}</div>;
  }
}
