import React from "react";
import axios from "axios";

//Import components
import TransparentButton from "../../common/TransparentButton";

export default class CTA extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      subtitle: "",
      btnText: "",
    };

    this.getHero = this.getHero.bind(this);
  }

  //API CALLS ==============================
  getHero() {
    axios.get("https://www.api.curiouscatcreative.com/hero").then((res) => {
      this.setState({
        title: res.data.title,
        subtitle: res.data.subtitle,
        btnText: res.data.btnText,
      });
    });
  }

  //LIFECYCLE METHODS ==================
  componentDidMount() {
    this.getHero();
  }
  render() {
    const { btnText, title, subtitle } = this.state;

    return (
      <div className="col-6">
        <div className="row text-left text-light justify-content-start p-3">
          <div className="col-12 col-md-10 py-2">
            <h1 className="display-3">{title}</h1>
          </div>
          <div className="col-12 col-md-8 py-2">
            <h1>{subtitle}</h1>
          </div>
          <div className="col-10 col-md-6 py-1">
            <TransparentButton link="/#contact" btnText={btnText} />
          </div>
        </div>
      </div>
    );
  }
}
