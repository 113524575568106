import React from "react";

//import components
import Profiles from "./Profiles";
import AboutText from "./AboutText";

export default class AboutContainer extends React.Component {
  render() {
    return (
      <div className="row justify-content-center text-center">
        <div className="col-12 col-lg-3 order-lg-2">
          <AboutText />
        </div>
        <div className="col-12 col-lg-4 order-lg-1">
          <Profiles />
        </div>
      </div>
    );
  }
}
