import React from "react";

//import router
import { Switch, Route } from "react-router-dom";

//import components
import Main from "./main/Main";
import Packages from "./packages/Packages";
import Privacy from "./privacy/Privacy";

export default class Pages extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/packages">
          <Packages />
        </Route>
        <Route path="/privacy">
          <Privacy />
        </Route>
        <Route path="/">
          <Main />
        </Route>
      </Switch>
    );
  }
}
