import React from "react";

import Navbar from "./navbar/Navbar";
import Footer from "./footer/Footer";

export default class Layout extends React.Component {
  render() {
    return (
      <div>
        <Navbar />
        {this.props.children}
        <Footer />
      </div>
    );
  }
}
