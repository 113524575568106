import React from "react";
import axios from "axios";

//import components
import Header from "../../common/Header";
import ProjectList from "./ProjectList";

export default class Portfolio extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      projects: []
    };

    this.getProjects = this.getProjects.bind(this);
  }

// API CALLS ==========================
  getProjects() {
    axios.get("https://www.api.curiouscatcreative.com/projects").then((res) => {
      this.setState({
        projects: res.data
      })
    });
  }

  //LIFECYCLE METHODS ==================
  componentDidMount() {
    this.getProjects();
  }

  render(){
    return(
      <div id="projects" className="container-fluid height-100 bg-light text-dark">
        <div className="row justify-content-center">
          <div className="col-12">
            <Header
              title="Success Stories"
              subtitle="We've helped some founders get their business rocking and rolling, check them out here"
            />
          </div>
          <div className="col-12 pb-5">
            <ProjectList
              projects={this.state.projects}
            />
          </div>
        </div>
      </div>
    )
  }
}
