import React from "react";

//import components

export default class ServiceCard extends React.Component {
  render() {
    const { title, imgsrc, list, index } = this.props;

    //Change styling for alternate cards
    let bg = "";
    if (index % 2 == 0) {
      bg = "bg-primary";
    } else {
      bg = "bg-secondary";
    }

    const listItems = list.map((listItem) => {
      return (
        <li className={"list-group-item " + bg} key={listItem._id}>
          {listItem.description}
        </li>
      );
    });

    return (
      <div
        className={
          "row no-gutters justify-content-center height-50 p-3 text-light shadow " + bg
        }
      >
        <div className="col-12 text-center">
          <h2 className="">{title}</h2>
        </div>
        <div className="col-4 col-md-3">
          <img src={imgsrc} className="img-fluid" />
        </div>
        <div className="col-12 py-3 text-center">
          <ul className="list-group list-group-flush">{listItems}</ul>
        </div>
      </div>
    );
  }
}
