import React from "react";

//import components
import Header from "../../common/Header";
import Form from "./Form";
import CalendlyBlock from "./CalendlyBlock";

export default function Contact() {
  return (
    <div id="contact" className="container-fluid height-50 gradient">
      <div className="row justify-content-center align-items-top text-light pb-5">
        <div className="col-12">
          <Header title="GET IN TOUCH" />
        </div>
        <div className="col-10 col-md-4 pb-3">
          <CalendlyBlock />
        </div>
        <div className="col-10 col-md-4 offset-md-2">
          <Form />
        </div>
      </div>
    </div>
  );
}
