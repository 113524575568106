import React from "react";

//Import components
//import XX from "./XX";

export default class Avatar extends React.Component {
  render() {
    const { btnText, link, imgsrc, colour } = this.props;
    return (
      <div className="row text-center">
        <div className="col-12 p-3">
          <img src={imgsrc} className="img-fluid" />
        </div>
        <div className="col-12">
          <a href={link} className={"btn btn-md btn-block border-" + colour} style={{"borderRadius" : 0}}>
            <strong>{btnText}</strong>
          </a>
        </div>
      </div>
    );
  }
}
