import React from "react";
import axios from "axios";

//Import components
import Terms from "./Terms";
import SocialIcons from "./SocialIcons";

export default class SocialBlock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      logoUrl: "",
    };

    this.getLogo = this.getLogo.bind(this);
  }

  getLogo() {
    axios.get("https://www.api.curiouscatcreative.com/logo").then((res) => {
      this.setState({
        logoUrl: res.data.image.url,
      });
    });
  }

  //LIFECYCLE METHODS ==================
  componentDidMount() {
    this.getLogo();
  }

  render() {
    return (
      <div>
        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-md-4 col-lg-4">
            <a href="/">
              <img src={this.state.logoUrl} width="100%" height="auto" />
            </a>
          </div>
          <div className="col-12 col-md-12 col-lg-6 text-center text-lg-left">
            <Terms />
          </div>
        </div>
      </div>
    );
  }
}
