import React from "react";

export default class PriceLine extends React.Component {
  render() {
    const {price, bundle, item, i, colour} = this.props;

    //Format price to show in rands
    const priceFormatted = new Intl.NumberFormat("en-ZA", {
      style: "currency",
      currency: "ZAR",
      maximumSignificantDigits: 2,
    }).format(price);

    return (
      <div className="row text-left py-2">
        <div className={"col-12 bg-light text-center text-" + colour}>
          <h5><small><strong>{bundle}</strong></small></h5>
        </div>
        <div className="col-12 col-md-4 order-md-2 p-2 height-15" style={{backgroundColor: `rgba(${255}, ${255}, ${255}, ${1/(i+2.5)})`}}>
          <h5>{priceFormatted}</h5>
        </div>
        <div className="col-12 col-md-8 order-md-1 p-2 height-15" style={{backgroundColor: `rgba(${255}, ${255}, ${255}, ${1/(i+2.5)})`}}>
          <p>{item}</p>
        </div>
      </div>
    );
  }
}
