import React from "react";

import PriceLine from "./PriceLine";

export default class PackageCard extends React.Component {
  render() {
    //Deconstruct props
    const { title, subtitle, priceList, imgsrc, colour } = this.props;

    const priceLines = priceList.map((price, index) => {
      return (
        <PriceLine key={price._id} item={price.item} price={price.price} bundle={price.bundle} i={index} colour={colour}/>
      );
    });

    return (
      <div
        className={
          "row text-center text-light height-80 justify-content-center bg-" +
          colour
        }
      >
        <div className="col-12 pt-2">
          <h2>{title}</h2>
        </div>

        <div className="col-6 col-md-4 col-lg-3 m-0 p-0">
          <img src={imgsrc} alt="" width="100%" height="auto" />
        </div>

        <div className="col-12 pt-2">
          <h4>
            <span className={"badge badge-pill badge-light text-" + colour}>
              {subtitle}
            </span>
          </h4>
        </div>
        <div className="col-11">{priceLines}</div>
      </div>
    );
  }
}
