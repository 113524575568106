import React from "react";

//Import components
import SocialBlock from "./socialBlock/SocialBlock";
import ContactBlock from "./contactBlock/ContactBlock";

export default class Footer extends React.Component {
  render(){
    return(
      <div id="footer" className="container-fluid bg-light height-10">
        <div className="row py-3">
          <div className="col-12 col-lg-6">
            <SocialBlock/>
          </div>
          <div className="col-lg-6 d-none d-lg-block">
            <ContactBlock/>
          </div>
        </div>
      </div>
    )
  }
}
