import React from "react";
import ReactMarkdown from "react-markdown";

export default class StoryBlock extends React.Component {
  render() {
    const { text } = this.props;
    return (
      <div className="row justify-content-center text-center text-light">
        <div className="col-12">
          <ReactMarkdown children={text} />
        </div>
      </div>
    );
  }
}
