import React from "react";

//import components
import ProjectCard from "./ProjectCard";


export default class PortfolioList extends React.Component {
  render() {
    const {projects} = this.props;

    let projectCards = projects.map((project) => {
        return (
          <div className="col-12 col-md-6 col-lg-3 p-4" key={project._id}>
            <ProjectCard
              imgsrc={project.media.url}
              link={project.link}
              title={project.title}
              description={project.description}
            />
          </div>
        )
    });

    return(
      <div className="row text-center justify-content-center">
        {projectCards}
      </div>
    )
  }
}
