import React from "react";

//import router
import { BrowserRouter as Router } from "react-router-dom";

//import components
import Layout from "./layout/Layout";
import Pages from "./pages/Pages";

export default class App extends React.Component {
  render() {
    return (
      <Router>
        <Layout>
          <Pages />
        </Layout>
      </Router>
    );
  }
}
