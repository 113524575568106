import React from "react";

import GradientButton from "../../common/GradientButton";

export default class ProjectCard extends React.Component {
  render() {
    //Destructure props
    const { imgsrc, link, title, description } = this.props;
    let button = "";

    if (link) {
      button = (
        <div className="col-12 px-5">
          <GradientButton link={link} btnText={"Visit"} />
        </div>
      );
    }

    return (
      <div className="row">
        <div className="col-12">
          <div className="overlay shadow">
            <img src={imgsrc} className="img-fluid rounded" />
            <div className="row no-gutters overlay-inner">
                <div className="col-12">
                  <h4 className="py-3">
                    <strong>{title}</strong>
                  </h4>
                </div>
                <div className="col-12">
                  <p className="p-3">
                    <strong>{description}</strong>
                  </p>
                </div>
                {button}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
