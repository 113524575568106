import React from "react";
import { render } from "react-dom";

//Bootstrap requirements
import "bootstrap";

//Import style
import "./scss/main.scss";

//Load main app Component
import App from "./js/App";

render(<App />, document.getElementById("root"));
