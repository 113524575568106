import React from "react";

import SocialIcons from "./SocialIcons";

export default class Terms extends React.Component {
  render(){
    return(
        <div className="row">
          <div className="col-12 p-0">
            <p className="m-0"><small>All rights reserved</small></p>
          </div>
          <div className="col-12 p-0">
            <p className="m-0"><small>Curious Cat Creative 2020</small></p>
          </div>
          <div className="col-12 p-0">
            <p className="m-0"><small><a href="/privacy">Privacy Policy</a> | Terms & Conditions</small></p>
          </div>
          <div className="col-2 offset-5  col-lg-3 offset-lg-0 p-0">
            <SocialIcons/>
          </div>
        </div>
    )
  }
}
