import React from "react";

//import components
import Header from "../../common/Header";
import AboutContainer from "./AboutContainer";

export default class About extends React.Component {
  render() {
    return(
      <div id="about" className="container-fluid height-100 bg-light">
        <div className="row justify-content-center">
          <div className="col-12 col-md-8 col-lg-12">
            <AboutContainer/>
          </div>
        </div>
      </div>
    )
  }
}
