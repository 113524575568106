import React from "react";

export default class GradientButton extends React.Component {
  render() {
    return (
      <a href={this.props.link} className="row no-gutters text-center" style={{"textDecoration": "none"}}>
        <div className="col-12">
          <div className="module-border-wrap">
            <div className="module">
              <strong>{this.props.btnText}</strong>
            </div>
          </div>
        </div>
      </a>
    );
  }
}
