import React from "react";

export default class Header extends React.Component {
  render() {
    return (
      <div className="row text-center justify-content-center pb-3 pt-5">
        <div className="col-12">
          <h2>
            {this.props.title}
          </h2>
        </div>
        <div className="col-12 col-md-8 col-lg-6">
          <h4>
            <small>{this.props.subtitle}</small>
          </h4>
        </div>
      </div>
    );
  }
}
