import React from "react";

import { FaFacebookSquare, FaLinkedin, FaInstagram } from "react-icons/fa";

export default class SocialIcons extends React.Component {
  render() {
    return (
      <div className="row no-gutters justify-content-center">
        <div className="col">
          <a href="https://www.facebook.com/curiouscatcreativeagency" ><FaFacebookSquare/></a>
        </div>
        <div className="col">
          <a href="https://www.instagram.com/curiouscatcreative_sa/"><FaInstagram/></a>
        </div>
        <div className="col">
          <a href="https://www.linkedin.com/company/curious-cat-creative/"><FaLinkedin/></a>
        </div>
      </div>
    );
  }
}
