import React from "react";

//import components
import Header from "../../common/Header";
import GradientButton from "../../common/GradientButton";
import ServiceList from "./ServiceList";

export default class Services extends React.Component {
  render() {
    return (
      <div id="services" className="container-fluid height-100 bg-light">
        <div className="row justify-content-center">
          <div className="col-12">
            <Header
              title="What We Help With"
              subtitle="We prepare new and existing businesses for investment and expansion by covering the four fundamentals of a tech-driven business"
            />
          </div>
          <div className="col-12 col-md-8 col-lg-12">
            <ServiceList />
          </div>
          <div className="col-12 col-md-8 col-lg-4 py-3">
            <GradientButton link="/#contact" btnText="CONTACT" />
          </div>
        </div>
      </div>
    );
  }
}
