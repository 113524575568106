import React from "react";

//import components
import CTA from "./CTA";

export default class Jumbotron extends React.Component {
  render() {
    return (
      <div className="jumbotron jumbotron-fluid hero mb-0 p-0">
        <div className="container-fluid">
          <div className="row height-95 align-items-center">
            <CTA />
          </div>
        </div>
      </div>
    );
  }
}
