import React from "react";
import axios from "axios";

//import components
import ServiceCard from "./ServiceCard";

export default class ServiceList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      services: [],
    };

    this.getServices = this.getServices.bind(this);
  }

  // API CALLS ==========================
  getServices() {
    axios.get("https://www.api.curiouscatcreative.com/services").then((res) => {
      this.setState({
        services: res.data,
      });
    });
  }

  //LIFECYCLE METHODS ==================
  componentDidMount() {
    this.getServices();
  }

  render() {
    const { services } = this.state;

    const serviceCards = services.map((service, index) => {
      return (
        <div className="col-12 col-lg-3 px-5 py-3" key={service._id}>
          <ServiceCard
            index={index}
            title={service.title}
            imgsrc={service.icon.url}
            list={service.serviceList}
          />
        </div>
      );
    });

    return <div className="row">{serviceCards}</div>;
  }
}
