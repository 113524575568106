import React from "react";

import Header from "../common/Header";
import GradientButton from "../common/GradientButton";
import PackageContainer from "./PackageContainer";

export default class Packages extends React.Component {
  render() {
    return (
      <div>
        <div id="packages" className="container-fluid height-100 bg-light">
          <div className="row justify-content-center">
            <div className="col-12">
              <Header
                title="Our packages"
                subtitle="We’ve put together some packages but every problem is different, so we look at each project individually when it comes across our desk."
              />
            </div>

            <div className="col-12 col-lg-11">
              <PackageContainer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
