import React from "react";

export default function Button(props) {
  const { link, btnText, onClick } = props;
  return (
    <a
      href={link}
      className="btn btn-lg btn-block btn-blue text-light"
      style={{ borderRadius: 0 }}
      onClick={onClick}
    >
      <strong>
        {btnText}
      </strong>

    </a>
  );
}
