import React from "react";

import { openPopupWidget } from "react-calendly";

import Button from "../../common/Button";
import { FaPhone } from "react-icons/fa";

function onClick() {
  handlePixel();
  const url = "https://calendly.com/cccprojects/ccc-intro-call";
  openPopupWidget({ url });
}

function handlePixel() {
  window.fbq("track", "Contact");
}

export default function CalendlyBlock() {
  return (
    <div className="row justify-content-center contactCard">
      <div className="col-12 text-center">
        <h2>BOOK A CALL</h2>
        <h4>
          <small>
            Schedule a chat with us directly and we'll go through what you need
          </small>
        </h4>
      </div>
      <div className="col-12 col-md-6">
        <Button btnText={<FaPhone size={28} />} onClick={onClick} />
      </div>
    </div>
  );
}
