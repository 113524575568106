import React from "react";

export default class Input extends React.Component {
  render() {
    const {
      id,
      label,
      type,
      name,
      placeholder,
      value,
      handleChange,
      required
    } = this.props;

    return (
      <div className="form-row">
        <label htmlFor={id} className="col-12 col-form-label">
          {label}
        </label>
        <div className="col-12">
          <input
            type={type}
            className="form-control"
            id={id}
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={handleChange}
          ></input>
        </div>
      </div>
    );
  }
}
